import { Box, Slider, styled } from "@mui/material"
import Draggable from "react-draggable"

const ZoomSlider = styled(Slider)({
  color: '#fff',
  '& .MuiSlider-markLabel': {
  color: '#fff',
  fontSize: '12px'
  },
  '& .MuiSlider-thumbSizeMedium': {
    borderRadius: '0px',
    height: '7px'
  }
})
  
  const marks = [
    {
      value: 20,
      label: 'Sidewalk',
    },
    {
      value: 18,
      label: 'Street',
    },
    {
      value: 12,
      label: 'City',
    },
    {
      value: 9,
      label: 'Region',
    },
    {
      value: 3,
      label: 'World',
    },
  ];

export const DraggableZoomSlider = ({
       handleChange,
} : { 
    handleChange: (val : number) => void,
}) => {

    const onChange = (val : number | number[]) => {
        if(!Array.isArray(val)){
            handleChange(val)
        }
    }

    return (
        <Draggable
                axis="both"
                handle=".handle"
                defaultPosition={{x: 0, y: 0}}
                grid={[25, 25]}
                scale={1}
                >
                <div style={{zIndex:'9999',backgroundColor:'#080808',height:'360px',width:'100px',color:'#fff',position:'absolute',right:'20px'}}>
                  <div className="handle" style={{marginBottom:'20px',backgroundColor:'#484848',width:'100%',textAlign:'center',cursor:'all-scroll',fontSize:'12px'}}>Zoom</div>
                  <Box sx={{ height: 300 }}>
                      <ZoomSlider
                          aria-label="Custom marks"
                          orientation="vertical"
                          sx={{
                              '& input[type="range"]': {
                              WebkitAppearance: 'slider-vertical',
                              },
                          }}
                          defaultValue={12}
                          max={20}
                          onChangeCommitted={(event, value) => onChange(value)}
                          step={1}
                          valueLabelDisplay="off"
                          marks={marks}
                      // style={{color:'#00f'}}
                      />
                  </Box>
                </div>
            </Draggable> 
    )}