import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import {
  getStaticProps as getLayoutStaticProps,
  LayoutProps,
} from '../components/Layout'
import sanity from '../lib/sanity'
import Map from '../components/googleMaps';
import { useAppContext } from '../AppContext';
import { useQueryRecords,useBoundaries } from '../lib/hooks/api';

const Index = () => {
  const { isLoading } = useAuth0()
  const { query = {} } = useAppContext()

  const {data} = useQueryRecords(query)
  const { data: boundaries = {} } = useBoundaries()
  if (isLoading) return <div>Loading...</div>

  return (
    <>
    {(data && <Map records={data} boundaries={boundaries}/>)}
    </>
  )
}

interface Props extends LayoutProps {
  example_image: string
}

export const getStaticProps = async (): Promise<{ props: Props }> => ({
  props: {
    ...(await sanity.fetch(
      `*[_type == 'settings' && _id == 'settings'][0] {
        "example_image": example_image.asset._ref
      }`,
    )),
    ...(await getLayoutStaticProps()).props,
  },
})

export default withAuthenticationRequired(Index)
