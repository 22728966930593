import { Key, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Collapse, Typography, Paper } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { useQueryRecords } from '../lib/hooks/api';
import { SunderlandData, BoundariesData } from '../lib/types'
import { useAppContext } from '../AppContext';
import { DraggableZoomSlider } from "./zoomSlider";

interface RowItem {
  row : {
      date?: number
      type: string
      category: string
      address: string
      name: string
      details: {
        [key: string] : string | number
      }
    }
  }

interface Props {
  records: SunderlandData[]
  boundaries: BoundariesData
}

function Row(props : RowItem) {
  const { row } = props
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.category}</TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">{row.address}</TableCell>
        <TableCell align="right">{row.date ? new Date(row.date).toLocaleDateString('en-GB') : ""}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              {Object.entries(row.details).map(([key, value], i) => {return <p key={i}><b>{key}</b>: {value}</p>})}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export const InfoWindow = ({
  open, 
  handleClose,
  selectedRecords
} : 
{
  open: boolean, 
  handleClose: () => void,
  selectedRecords: SunderlandData[]
  
}) => {

  const disctinctRecords = selectedRecords.filter(
    (thing: { address: string; }, i: number, arr) => arr.findIndex(t => t.address === thing.address) === i
  )

return (
  <Dialog
    open={open}
    onClose={() => {
      handleClose()
    }}
    fullWidth={true}
    maxWidth = {'md'}
  >
    <DialogTitle>Details</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
        >
        </Box>

         {disctinctRecords.map((cl: SunderlandData) => {
            return(
              <>
                <b>Name:</b> {cl.name}<br />
                <b>Category:</b> {cl.category}<br />
                <b>Type:</b> {cl.type}<br />
                <b>Address:</b>	{cl.address}<br />
                <b>Date:</b> {cl.date ? new Date(cl.date).toLocaleDateString('en-GB') : ""}<br />

                <TableContainer component={Paper} style={{margin:'20px 10px 20px 10px'}}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Category</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right">Address</TableCell>
                        <TableCell align="right">Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedRecords.filter((record: { address: string; }) => record.address == cl.address).map((row: { date?: number | undefined; type: string; category: string; address: string; name: string; details: { [key: string]: string | number; }; }, i: Key | null | undefined) => (
                        <Row key={i} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
              )
              
          })
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
  </Dialog>
);
}

export default function Map({boundaries} : Props) {
  const { query = {}, filter = []} = useAppContext()
  const {data: records = []} = useQueryRecords(query)
  const googleKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false)
  const [selectedRecords, setSelectedRecords] = useState<SunderlandData[]>()
  const mapRef = useRef<google.maps.Map>()

  const handleChange = (newValue: number) => {
    if(mapRef.current) mapRef.current.setZoom(newValue)

}

  
  const stringToColour = function(str : string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  const setGoogleMapRef = (map: google.maps.Map, maps: typeof google.maps) => {
    mapRef.current = map

    Object.keys(boundaries).map((key)=>{
        const ward = new maps.Polygon({
          paths: boundaries[key].path,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillOpacity: 0.35,
          fillColor: stringToColour(key)
        });

        ward.setMap(map);
    })

    // const outerCoords = [
    //   { lat: 25.774, lng: -80.19 },
    //   { lat: 18.466, lng: -66.118 },
    //   { lat: 32.321, lng: -64.757 },
    // ];
  
    // // Define the LatLng coordinates for the polygon's inner path.
    // // Note that the points forming the inner path are wound in the
    // // opposite direction to those in the outer path, to form the hole.
    // const innerCoords = [
    //   { lat: 28.745, lng: -70.579 },
    //   { lat: 29.57, lng: -67.514 },
    //   { lat: 27.339, lng: -66.668 },
    // ];
  
    // // Construct the polygon, including both paths.
    // const bermudaTriangle = new google.maps.Polygon({
    //   paths: [outerCoords, innerCoords],
    //   strokeColor: "#FFC107",
    //   strokeOpacity: 0.8,
    //   strokeWeight: 2,
    //   fillColor: "#FFC107",
    //   fillOpacity: 0.35,
    // });
  
    //bermudaTriangle.setMap(map);

    const markers =
      records &&
      records.filter(record => {
        const hasCoords = record.lat && record.lon
        return hasCoords && filter.includes(record.type)
      }).map((marker) => {
        // let iconMarker = "https://lh3.googleusercontent.com/bECXZ2YW3j0yIEBVo92ECVqlnlbX9ldYNGrCe0Kr4VGPq-vJ9Xncwvl16uvosukVXPfV=w300"

        const refMarker = new maps.Marker({
          position: { lat: marker.lat as number, lng: marker.lon as number },
          title: marker.name,
          label: marker.category,
          //  icon: iconMarker
        });

        let details = ''
        Object.entries(marker.details).map(([key, value], i) => {
          details += `<p key=${i}><b>${key}</b>: ${value}</p>`
        })

        const infoWindow = new maps.InfoWindow({
          content: `<h1>${marker.category}</h1>
          <p><b>Name:</b> ${marker.name}</p>
          <p><b>Category:</b> ${marker.category}</p>
          <p><b>Type:</b> ${marker.type}</p>
          <p><b>Address:</b>	${marker.address}</p>
          <p><b>Date:</b> ${marker.date}</p>
          <p>${details}</p>
          `
        });


        refMarker.addListener("click", () => {
          infoWindow.open({
            anchor: refMarker,
            map,
            shouldFocus: false,
          });
        });

        return refMarker;
      });

    const markerClusterer = new MarkerClusterer(map, markers, {
      imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      minimumClusterSize: 2
    });

    // CLUSTERED ONCLICK EVENT WITH INFO
    google.maps.event.addListener(markerClusterer, 'clusterclick', function(cluster: { center_: { lat: () => number | undefined; lng: () => number | undefined; }; }){
      const zoomLevel = map.getZoom() || 0
      if(zoomLevel >= 22) {
        // Get distinct addresses
        const selected = records.filter(record => record.lat == cluster.center_.lat() && record.lon == cluster.center_.lng() && record.category)
        setSelectedRecords(selected)

        // Open Dialog Window
        setOpen(true)

      }
    });
  };

  return (
    <div className="app" style={{ height: "100vh", width: "100%" }}>
      <DraggableZoomSlider handleChange={handleChange}></DraggableZoomSlider>

      <GoogleMapReact
        bootstrapURLKeys={{ key: googleKey }}
        defaultCenter={{lat: 54.9069, lng:-1.3838}}
        zoom={13}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
        style={{width:'100%'}}
        // NEXT LINE NEEDED FOR FILTER TO UPDATE BUT CAUSES LOWEST ZOOM CLICK TO RESET ZOOM
        key={new Date().getTime()}
      >
        <InfoWindow open={open} handleClose={handleClose} selectedRecords={selectedRecords || []}></InfoWindow>
      </GoogleMapReact>
      
    </div>
  );
}